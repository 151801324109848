import { FC, Fragment, memo, useCallback, useEffect, useState } from 'react'
import { useSpeechRecognition } from 'react-speech-recognition'

import {
  useAnalyzeAnswerMutation,
  useFinishActivityMutation,
  useSaveQuestionResultMutation,
  useStartActivityMutation,
  useTranscribeAnswerMutation
} from '../../app/api/activityApi'
import {
  IAnswer,
  IQuestion,
  VrMode,
  getCheckIconPosition,
  getHelpPointButtonPosition,
  getHelpWrapperHeight,
  getModalTitlePosition,
  getPrimaryButtonPosition,
  getQuestionModalHeight,
  getRepeatButtonPosition,
  getRetryNotificationIconPosition,
  getRetryNotificationTextPosition,
  getTitlePosition,
  logVrInfo
} from '../../shared'
import AnswerItem from '../../shared/ui/answer-item'
import CompleteButton from '../../shared/ui/complete-button'
import HandControls from '../../shared/ui/hand-controls'
import HelpPointButton from '../../shared/ui/help-point-button'
import HelpWrapper from '../../shared/ui/help-wrapper'
import PrimaryButtonWrapper from '../../shared/ui/primary-button-wrapper'
import ProcessingNotification from '../../shared/ui/processing-notification'
import QuestionTitle from '../../shared/ui/question-title'
import QuestionWrapper from '../../shared/ui/question-wrapper'
import RecordButton from '../../shared/ui/record-button'
import RepeatButton from '../../shared/ui/repeat-button'
import ResultsWrapper from '../../shared/ui/results-wrapper'
import RetryNotification from '../../shared/ui/retry-notification'
import SkipButton from '../../shared/ui/skip-button'
import StartButton from '../../shared/ui/start-button'
import { registerPanel } from './panel'

declare global {
  interface Window {
    transcription: string
    startedAt: number
    answersResults: IAnswer[]
  }
}

interface Props {
  questions: IQuestion[]
  sceneUrl: string
  language: string
  mode: VrMode
  assignedActivityId?: string | null
  completeCallback: () => void
}

const VrSceneWidget: FC<Props> = ({
  questions,
  sceneUrl,
  assignedActivityId,
  language,
  completeCallback,
  mode
}) => {
  const { transcript, resetTranscript, browserSupportsSpeechRecognition } =
    useSpeechRecognition()
  const [answersResults, setAnswersResults] = useState<IAnswer[]>([])
  const [startedAt, setStartedAt] = useState(0)

  const [transcribe] = useTranscribeAnswerMutation()
  const [analyze] = useAnalyzeAnswerMutation()
  const [startTracking] = useStartActivityMutation()
  const [finishTracking] = useFinishActivityMutation()
  const [saveQuestionResult] = useSaveQuestionResultMutation()

  const startCallback = useCallback(async () => {
    if (assignedActivityId) {
      return await startTracking({ id: assignedActivityId }).unwrap()
    }
  }, [assignedActivityId, startTracking])

  useEffect(() => {
    const { userAgent } = navigator
    const isNotSupportedBrowser =
      userAgent.includes('Android') || userAgent.includes('Linux')

    const isSupport = browserSupportsSpeechRecognition && !isNotSupportedBrowser

    registerPanel({
      questions,
      resetTranscript,
      setAnswersResults,
      browserSupportsSpeechRecognition: isSupport,
      setStartedAt,
      language,
      mode,
      transcribe,
      analyze,
      finishTracking,
      saveQuestionResult,
      completeCallback,
      startCallback
    })

    logVrInfo(
      mode,
      browserSupportsSpeechRecognition,
      !isNotSupportedBrowser,
      isSupport
    )

    return () => {
      delete AFRAME.components['info-panel']
    }
  }, [])

  useEffect(() => {
    window.transcription = transcript
  }, [transcript])

  useEffect(() => {
    window.answersResults = answersResults
  }, [answersResults])

  useEffect(() => {
    window.startedAt = startedAt
  }, [startedAt])

  const getComponents = () => {
    return (
      <Fragment>
        <StartButton />
        {questions.map((question) => {
          const modalHeight = getQuestionModalHeight(
            question.answers.length,
            question.text.length
          )
          return (
            <Fragment key={question.id}>
              <QuestionWrapper
                id={`questionWrapper_${question.id}`}
                height={modalHeight}
              >
                <QuestionTitle
                  id={`question_${question.id}`}
                  value={question.text}
                  position={getModalTitlePosition(
                    modalHeight,
                    question.text.length
                  )}
                />
                {question.answers.map((answer, key) => {
                  return (
                    <AnswerItem
                      key={`key_${answer.id}`}
                      answerId={answer.id}
                      isCorrect={answer.isCorrect}
                      titlePosition={getTitlePosition(
                        key,
                        modalHeight,
                        question.text.length
                      )}
                      value={answer.text}
                      checkIconPosition={getCheckIconPosition(
                        key,
                        modalHeight,
                        question.text.length
                      )}
                    />
                  )
                })}
                <RetryNotification
                  questionId={question.id}
                  iconPosition={getRetryNotificationIconPosition(modalHeight)}
                  textPosition={getRetryNotificationTextPosition(modalHeight)}
                />
                <ProcessingNotification
                  questionId={question.id}
                  iconPosition={getRetryNotificationIconPosition(modalHeight)}
                  textPosition={getRetryNotificationTextPosition(modalHeight)}
                />
                <RepeatButton
                  id={question.id}
                  position={getRepeatButtonPosition(modalHeight)}
                />
                <PrimaryButtonWrapper
                  id={question.id}
                  position={getPrimaryButtonPosition(modalHeight)}
                >
                  {mode === VrMode.preview ? (
                    <SkipButton id={question.id} />
                  ) : (
                    <RecordButton id={question.id} />
                  )}
                </PrimaryButtonWrapper>

                {question.hintText && (
                  <HelpPointButton
                    id={`helpOpenButton_${question.id}`}
                    position={getHelpPointButtonPosition(modalHeight)}
                  />
                )}
              </QuestionWrapper>
              {question.hintText && (
                <HelpWrapper
                  id={question.id}
                  height={getHelpWrapperHeight(
                    question.hintText,
                    modalHeight,
                    language
                  )}
                  text={question.hintText}
                />
              )}
            </Fragment>
          )
        })}
        {mode === VrMode.preview ? (
          <CompleteButton position="0 -1.54 1.3" />
        ) : (
          <ResultsWrapper answersResults={answersResults} />
        )}
      </Fragment>
    )
  }

  const gazeComponents = () => {
    return (
      <Fragment>
        <a-entity position="0 3 -2.5" id="ui">
          {getComponents()}
        </a-entity>
        <a-camera
          user-height="0"
          wasd-controls-enabled="false"
          look-controls-enabled="true"
        >
          <a-cursor raycaster="objects: .raycastable" />
        </a-camera>
      </Fragment>
    )
  }

  const handComponents = () => {
    return (
      <Fragment>
        <HandControls />
        <a-camera user-height="0" wasd-controls-enabled="false">
          <a-entity id="ui">{getComponents()}</a-entity>
        </a-camera>
      </Fragment>
    )
  }

  return (
    <a-scene
      cursor="rayOrigin: mouse; fuse: false"
      raycaster="objects: .raycastable"
      xr-mode-ui="XRMode: vr;"
    >
      <a-light type="ambient" color="white"></a-light>
      <a-assets timeout="3500">
        <video id="video" crossOrigin="anonymous" preload={'auto'}>
          <source type="video/mp4" src={sceneUrl} />
        </video>
      </a-assets>

      <a-entity info-panel />

      {mode === VrMode.demo ? gazeComponents() : handComponents()}

      <a-videosphere rotation="0 285 0" src="#video" />
    </a-scene>
  )
}

export default memo(VrSceneWidget)
