import { CircularProgress } from '@mui/material'
import React, { memo } from 'react'

import { Theme } from '../../constants'
import { Wrapper } from './styles'

const Loader = () => {
  return (
    <Wrapper>
      <CircularProgress size={90} style={{ color: Theme.colors.sky }} />
    </Wrapper>
  )
}

export default memo(Loader)
