import 'aframe'
import 'aframe-troika-text'
import React, { FC } from 'react'

import { Theme } from '../../constants'
import { useCustomFont } from '../../hooks'

interface Props {
  position: string
}

const CompleteButton: FC<Props> = ({ position }) => {
  const fontProps = useCustomFont()

  return (
    <a-entity
      id="completeButton"
      geometry="primitive: plane;width: 0.5; height: 0.15"
      material={`src: url(/assets/button-material.svg); transparent: true; color: white;`}
      position={position}
      animation__scale="property: scale; to: 1.1 1.1 1.1; dur: 200; startEvents: mouseenter"
      animation__scale_reverse="property: scale; to: 1 1 1; dur: 200; startEvents: mouseleave"
      visible="false"
    >
      <a-entity
        geometry="primitive: plane;width: 0.4; height: 0.15"
        material={`color: ${Theme.colors.blue}`}
        visible="true"
        position="0 0 0.001"
      >
        <a-troika-text
          anchor="left"
          align="left"
          value="Complete"
          position="-0.155 0 0"
          color={Theme.colors.white}
          font-size="0.07"
          {...fontProps}
        />
      </a-entity>
    </a-entity>
  )
}

export default CompleteButton
