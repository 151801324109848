import 'aframe'
import React, { FC } from 'react'

const CheckAnswerIcon: FC<{
  position: string
  iconPath: string
  id?: string
  show?: boolean
}> = (props) => {
  return (
    <a-entity
      id={props.id}
      visible={!!props.show}
      geometry="primitive: plane;width: 0.1; height: 0.1;"
      material={`src: url(${props.iconPath}); transparent: true;`}
      position={props.position}
    />
  )
}

export default CheckAnswerIcon
