import 'aframe'
import React, { FC } from 'react'

import { Theme } from '../../constants'

const QuestionWrapper: FC<{
  id: string
  height: number
  children: any
  visible?: boolean
}> = (props) => {
  return (
    <a-entity
      id={props.id}
      position="0 -1.4 0.6"
      geometry={`primitive: plane; width: 1.5; height: ${props.height};`}
      material={`src: url(/assets/modal-wrapper.svg); transparent: true;`}
      visible={!!props.visible}
    >
      <a-entity
        geometry={`primitive: plane; width: 1.4; height: ${
          props.height - 0.05
        };`}
        material={`color: ${Theme.colors.white}`}
        visible="true"
      >
        {props.children}
      </a-entity>
    </a-entity>
  )
}

export default QuestionWrapper
