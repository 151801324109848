export const ROUTES = {
  HOME: '/',
  VR_INTERACTIVE: {
    path: '/vr/:activityId/:assignedActivityId',
    get: (activityId: string, assignedActivityId: string) =>
      `/vr/${activityId}/${assignedActivityId}`
  },
  VR_PREVIEW: {
    path: '/vr/:activityId/preview',
    get: (activityId: string) => `/vr/${activityId}/preview`
  },
  VR_DEMO: {
    path: '/vr/:activityId',
    get: (activityId: string) => `/vr/${activityId}`
  },
  VR_EDIT_QUESTION_PREVIEW: '/vr/question/preview'
}

export const API_ROUTES = {
  ACTIVITY: (activityId: string) => `activities/${activityId}`,
  STUDENT_RESULTS: (id: string) => `students/results/${id}`,
  ANALYZE: (id: string) => `activities/answer/${id}/analyze`,
  TRANSCRIBE: 'activities/answer/transcribe',
  REFRESH: 'auth/refresh',
  DEMO_ACTIVITY: (activityId: string) => `activities/${activityId}/demo`,
  LOGIN_DEMO_USER: 'auth/login/demo'
}
