import 'aframe'

interface Props {
  id: string
  position: string
}

const HelpPointButton = ({ id, position }: Props) => {
  return (
    <a-entity
      id={id}
      geometry="primitive: plane; width: 0.2; height: 0.2;"
      animation__scale="property: scale; to: 1.1 1.1 1.1; dur: 200; startEvents: mouseenter"
      animation__scale_reverse="property: scale; to: 1 1 1; dur: 200; startEvents: mouseleave"
      material="color: white;"
      position={position}
    >
      <a-entity
        geometry="primitive: plane; width: 0.2; height: 0.2;"
        material="src: url(/assets/question.png); color: white; transparent: true;"
        position="0 0 0"
      />
    </a-entity>
  )
}

export default HelpPointButton
