import 'aframe'
import React, { FC } from 'react'

const PrimaryButtonWrapper: FC<{
  id: string
  position: string
  children: React.ReactNode
}> = (props) => {
  const { id, position, children } = props
  return (
    <a-entity
      id={`primaryButton_${id}`}
      animation__scale="property: scale; to: 1.1 1.1 1.1; dur: 200; startEvents: mouseenter"
      animation__scale_reverse="property: scale; to: 1 1 1; dur: 200; startEvents: mouseleave"
      position={position}
    >
      {children}
    </a-entity>
  )
}

export default PrimaryButtonWrapper
