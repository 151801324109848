import 'aframe'
import 'aframe-troika-text'
import React, { FC } from 'react'

import { Theme } from '../../constants'
import { useCustomFont } from '../../hooks'

const RetryNotification: FC<{
  questionId: string
  iconPosition: string
  textPosition: string
}> = (props) => {
  const fontProps = useCustomFont()

  return (
    <>
      <a-entity
        visible="false"
        id={`retryIcon_${props.questionId}`}
        geometry="primitive: plane;width: 0.1; height: 0.1;"
        material="src: url(/assets/retry-info.svg); transparent: true;"
        position={props.iconPosition}
        className="raycastable menu-button"
        animation__scale="property: scale; to: 1.1 1.1 1.1; dur: 200; startEvents: mouseenter"
        animation__scale_reverse="property: scale; to: 1 1 1; dur: 200; startEvents: mouseleave"
      />
      <a-entity
        id={`retryText_${props.questionId}`}
        position={props.textPosition}
        visible="false"
      >
        <a-troika-text
          anchor="left"
          align="left"
          value="Speech recognition failed! Please try again."
          position="0 -0.01 0"
          max-width="1"
          color={Theme.colors.black}
          font-size="0.045"
          {...fontProps}
        />
      </a-entity>
    </>
  )
}

export default RetryNotification
