import 'aframe'
import React, { Fragment } from 'react'

import { Theme } from '../../constants'

const HandControls = () => {
  return (
    <Fragment>
      <a-entity
        id="leftHand"
        laser-controls="hand: left"
        raycaster="objects: .raycastable"
      />
      <a-entity
        id="rightHand"
        laser-controls="hand: right"
        raycaster="objects: .raycastable"
        line={`color: ${Theme.colors.line}`}
      />
    </Fragment>
  )
}

export default HandControls
