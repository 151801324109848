import 'aframe'
import SpeechRecognition from 'react-speech-recognition'

import { SPEECH_LANGUAGES, VrComponentOptions, VrMode } from '../../shared'

export function levenshteinDistance(a, b) {
  const matrix = []
  for (let i = 0; i <= a.length; i++) {
    matrix[i] = [i]
  }
  for (let j = 0; j <= b.length; j++) {
    matrix[0][j] = j
  }
  for (let i = 1; i <= a.length; i++) {
    for (let j = 1; j <= b.length; j++) {
      if (a.charAt(i - 1) === b.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1]
      } else {
        matrix[i][j] = Math.min(
          matrix[i - 1][j - 1] + 1,
          Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1)
        )
      }
    }
  }
  return matrix[a.length][b.length]
}

function similarityScore(original, input) {
  const formattedOriginal = original.toLowerCase()
  const formattedInput = input.toLowerCase()
  const distance = levenshteinDistance(formattedOriginal, formattedInput)
  const maxLength = Math.max(original.length, input.length)
  const score = (maxLength - distance) / maxLength
  return Math.round(score * 100)
}

/* global AFRAME */
export function registerPanel(params) {
  const {
    questions,
    resetTranscript,
    setAnswersResults,
    browserSupportsSpeechRecognition,
    startCallback,
    setStartedAt,
    language,
    mode,
    analyze,
    transcribe,
    finishTracking,
    saveQuestionResult,
    completeCallback
  } = params

  delete AFRAME.components['info-panel']
  let mediaRecorder = null
  let activityStarted = false
  let resultId = null
  let correctAnswersCount = 0
  let timer = null
  let mainStream = null
  AFRAME.registerComponent('info-panel', {
    ...VrComponentOptions,
    init: function () {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          mainStream = stream
          this.isEnabledMicro = true
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          console.error('Microphone access denied')
          alert('Microphone access denied')
          this.isEnabledMicro = false
        })

      this.ui = this.getElementById('ui')
      this.videoEl = this.getElementById('video')
      this.startButton = this.getElementById('startButton')
      this.completeButton = this.getElementById('completeButton')

      this.isRecording = false

      questions.forEach((question, index) => {
        this.videoEl = this.getElementById('video')
        this.videoEl.pause()
        this[`isAnsweredQuestion_${question.id}`] = false

        this.videoEl.addEventListener('timeupdate', () => {
          if (
            this.videoEl.currentTime >= question.breakpoint &&
            !this[`isAnsweredQuestion_${question.id}`]
          ) {
            this.videoEl.pause()
            this.toggleElementVisibility(`questionWrapper_${question.id}`, true)
            this[`isAnsweredQuestion_${question.id}`] = true

            this.repeatBtnStateToggle(question.id, true)
            this.primaryBtnStateToggle(question.id, true)

            const isHelpExist = this.getElementById(`help_${question.id}`)
            if (isHelpExist) {
              this.helpOpenBtnStateToggle(question.id, true)
            }
          }
        })

        this.getElementById(`primaryButton_${question.id}`).addEventListener(
          'click',
          () => {
            this.onPrimaryButtonClick(question.id, question.answers)
          }
        )

        this.getElementById(`repeatButton_${question.id}`).addEventListener(
          'click',
          () => {
            let prevBreakpoint =
              index === 0 ? 0 : questions[index - 1].breakpoint
            this.videoEl.currentTime = prevBreakpoint + 0.1

            this[`isAnsweredQuestion_${question.id}`] = false

            this.hideElements(question.id)
            this.videoEl.play()
          }
        )

        if (this.getElementById(`help_${question.id}`)) {
          this.getElementById(`helpOpenButton_${question.id}`).addEventListener(
            'click',
            () => {
              this.toggleElementVisibility(
                `questionWrapper_${question.id}`,
                false
              )
              this.repeatBtnStateToggle(question.id, false)
              this.primaryBtnStateToggle(question.id, false)

              this.helpCloseBtnStateToggle(question.id, true)
              this.helpOpenBtnStateToggle(question.id, false)
              this.toggleElementVisibility(`help_${question.id}`, true)
            }
          )
          this.getElementById(
            `helpCloseButton_${question.id}`
          ).addEventListener('click', () => {
            this.toggleElementVisibility(`questionWrapper_${question.id}`, true)
            this.repeatBtnStateToggle(question.id, true)
            this.primaryBtnStateToggle(question.id, true)

            this.helpCloseBtnStateToggle(question.id, false)
            this.helpOpenBtnStateToggle(question.id, true)
            this.toggleElementVisibility(`help_${question.id}`, false)
          })
        }
      })

      this.onStartButtonClick = this.onStartButtonClick.bind(this)
      this.onCompleteButtonClick = this.onCompleteButtonClick.bind(this)
      this.recordAnswer = this.recordAnswer.bind(this)
      this.recordBtnClick = this.recordBtnClick.bind(this)
      this.calculateResult = this.calculateResult.bind(this)
      this.hideElements = this.hideElements.bind(this)
      this.stopRecording = this.stopRecording.bind(this)

      window.addEventListener('offline', () => {
        if (!activityStarted) {
          this.startButton.removeEventListener('click', this.onStartButtonClick)
          this.toggleElementVisibility('startButton', false)
          this.toggleElementVisibility('waitElement', true)
        }
      })

      window.addEventListener('online', () => {
        if (!activityStarted) {
          this.startButton.addEventListener('click', this.onStartButtonClick, {
            once: true
          })
          this.toggleElementVisibility('startButton', true)
          this.toggleElementVisibility('waitElement', false)
        }
      })

      this.startButton.addEventListener('click', this.onStartButtonClick, {
        once: true
      })

      this.completeButton.addEventListener(
        'click',
        this.onCompleteButtonClick,
        { once: true }
      )

      this.videoEl.addEventListener('ended', () => {
        this.completeBtnStateToggle(true)
        if (mode !== VrMode.preview) {
          this.toggleElementVisibility('resultsWrapper', true)
        }
      })
      this.ui.object3D.position.x = 0
      this.ui.object3D.position.y = 1.6
      this.ui.object3D.position.z = -2.5
      if (AFRAME.utils.device.isMobileVR()) {
        this.ui.object3D.position.z = -3.5
        this.ui.object3D.position.y = 1.25
      }
    },

    toggleRecordBtnIsRecording: function (questionId, isRecording) {
      const btn = this.getElementById(`recordButton_${questionId}`)
      const btnWrapper = this.getElementById(`primaryButton_${questionId}`)
      if (isRecording) {
        btnWrapper.object3D.position.x = 0
        btn.setAttribute('material', 'color', '#ff0000')
      } else {
        btnWrapper.object3D.position.x = 0.15
        btn.setAttribute('material', 'color', '#ffffff')
      }
    },

    primaryBtnStateToggle: function (questionId, visible) {
      this.toggleElementClickEvents(`primaryButton_${questionId}`, visible)
      this.toggleElementVisibility(`primaryButton_${questionId}`, visible)

      if (mode === VrMode.preview) {
        this.skipBtnStateToggle(questionId, visible)
      } else {
        this.recordBtnStateToggle(questionId, visible)
      }
    },

    recordBtnStateToggle: function (questionId, visible) {
      this.toggleElementClickEvents(`recordButton_${questionId}`, visible)
      this.toggleElementVisibility(`recordButton_${questionId}`, visible)
    },

    skipBtnStateToggle: function (questionId, visible) {
      this.toggleElementClickEvents(`skipButton_${questionId}`, visible)
      this.toggleElementVisibility(`skipButton_${questionId}`, visible)
    },

    completeBtnStateToggle: function (visible) {
      this.toggleElementClickEvents('completeButton', visible)
      this.toggleElementVisibility('completeButton', visible)
    },

    repeatBtnStateToggle: function (questionId, visible) {
      this.toggleElementClickEvents(`repeatButton_${questionId}`, visible)
      this.toggleElementVisibility(`repeatButton_${questionId}`, visible)
    },

    retryMessageVisibilityToggle: function (questionId, visible) {
      this.toggleElementVisibility(`retryIcon_${questionId}`, visible)
      this.toggleElementVisibility(`retryText_${questionId}`, visible)
    },

    processingMessageVisibilityToggle: function (questionId, visible) {
      this.toggleElementVisibility(`processingIcon_${questionId}`, visible)
      this.toggleElementVisibility(`processingText_${questionId}`, visible)
    },

    helpOpenBtnStateToggle: function (questionId, visible) {
      this.toggleElementVisibility(`helpOpenButton_${questionId}`, visible)
      this.toggleElementClickEvents(`helpOpenButton_${questionId}`, visible)
    },

    helpCloseBtnStateToggle: function (questionId, visible) {
      this.toggleElementVisibility(`helpCloseButton_${questionId}`, visible)
      this.toggleElementClickEvents(`helpCloseButton_${questionId}`, visible)
    },

    // for debug
    skipQuestion: function (questionId) {
      this.videoEl.play()
      this.hideElements(questionId)
    },

    onStartButtonClick: async function () {
      if (mode === VrMode.interactive) {
        await startCallback().then((res) => {
          resultId = res.id
        })
      }
      this.videoEl.play()
      activityStarted = true
      setStartedAt(Date.now())
      this.toggleElementVisibility('startButton', false)
      this.toggleElementClickEvents('startButton', false)
    },

    hideElements: function (questionId) {
      this.helpCloseBtnStateToggle(questionId, false)
      this.helpOpenBtnStateToggle(questionId, false)

      this.toggleElementVisibility(`questionWrapper_${questionId}`, false)

      this.toggleElementVisibility(`help_${questionId}`, false)

      this.repeatBtnStateToggle(questionId, false)
      this.primaryBtnStateToggle(questionId, false)

      this.retryMessageVisibilityToggle(questionId, false)
      this.processingMessageVisibilityToggle(questionId, false)
    },

    onCompleteButtonClick: async function () {
      if (mode === VrMode.interactive) {
        const now = new Date().toISOString()
        await finishTracking({ id: resultId, finishedAt: now })
      }
      completeCallback()
    },

    onPrimaryButtonClick: function (questionId, answers) {
      if (mode === VrMode.preview) {
        this.skipQuestion(questionId)
      } else {
        this.recordBtnClick(questionId, answers)
      }
    },

    calculateResult: async function (questionId, answers, formDataToAnalyze) {
      const ansScore = answers.map((answer) => {
        const score = similarityScore(answer.text, window.transcription)
        return {
          score,
          id: answer.id,
          isCorrect: answer.isCorrect,
          text: answer.text
        }
      })

      const maxScoreAnswer = ansScore.reduce(
        (maxScoreAnswer, currentAnswer) => {
          return currentAnswer.score > maxScoreAnswer.score
            ? currentAnswer
            : maxScoreAnswer
        },
        ansScore[0]
      )

      if (maxScoreAnswer.score >= 70) {
        const result = {
          text: maxScoreAnswer.text,
          id: maxScoreAnswer.id
        }

        if (maxScoreAnswer.isCorrect) {
          this.toggleElementVisibility(`correct_${maxScoreAnswer.id}`, true)
          setAnswersResults((prev) => [...prev, { ...result, isCorrect: true }])
          correctAnswersCount += 1
          if (mode === VrMode.interactive) {
            const score = Math.round(
              (correctAnswersCount / questions.length) * 100
            )
            await saveQuestionResult({
              id: resultId,
              score
            })
            await analyze({
              id: maxScoreAnswer.id,
              formData: formDataToAnalyze
            })
          }
        } else {
          this.toggleElementVisibility(`incorrect_${maxScoreAnswer.id}`, true)
          setAnswersResults((prev) => [
            ...prev,
            { ...result, isCorrect: false }
          ])
        }

        setTimeout(() => {
          this.videoEl.play()
          this.hideElements(questionId)
        }, 1250)
      } else {
        this.helpOpenBtnStateToggle(questionId, true)
        this.retryMessageVisibilityToggle(questionId, true)
        this.repeatBtnStateToggle(questionId, true)
        this.primaryBtnStateToggle(questionId, true)
      }
    },

    stopRecording: function (questionId, answers) {
      clearInterval(timer)
      timer = null

      mediaRecorder.stop()

      mediaRecorder.ondataavailable = async (e) => {
        const fileName = `recorded_audio_${Date.now()}.wav`
        const audioBlob = new Blob([e.data], { type: 'audio/wav' })
        const formData = new FormData()
        formData.append('file', audioBlob, fileName)
        formData.append('language', language)

        const formDataToAnalyze = new FormData()
        formDataToAnalyze.append('file', audioBlob, fileName)
        formDataToAnalyze.append('resultId', resultId)

        // Not all browsers support Web Speech API
        if (browserSupportsSpeechRecognition) {
          await SpeechRecognition.stopListening()
          await this.calculateResult(questionId, answers, formDataToAnalyze)
        }

        // If the browser does not support the Web Speech API,
        // then we use the Rest API for recognition.
        if (!browserSupportsSpeechRecognition) {
          this.processingMessageVisibilityToggle(questionId, true)
          await transcribe({ formData })
            .unwrap()
            .then((res) => {
              window.transcription = res.transcription
            })
          this.processingMessageVisibilityToggle(questionId, false)
          await this.calculateResult(questionId, answers, formDataToAnalyze)
        }
      }
    },

    recordBtnClick: async function (questionId, answers) {
      if (!this.isEnabledMicro) {
        // eslint-disable-next-line no-console
        console.error('Microphone access denied')
        alert('Microphone access denied')
        return
      }

      if (this.isRecording) {
        this.stopRecording(questionId, answers)

        this.primaryBtnStateToggle(questionId, false)
        this.toggleRecordBtnIsRecording(questionId, false)
        this.isRecording = false
      } else {
        this.recordAnswer(questionId, answers)

        this.helpOpenBtnStateToggle(questionId, false)
        this.helpCloseBtnStateToggle(questionId, false)
        this.retryMessageVisibilityToggle(questionId, false)
        this.repeatBtnStateToggle(questionId, false)
        this.toggleRecordBtnIsRecording(questionId, true)
        this.isRecording = true
      }
    },

    recordAnswer: async function (questionId, answers) {
      let seconds = 0
      timer = setInterval(() => {
        seconds++
        if (seconds === 30) {
          clearInterval(timer)
          timer = null
          this.stopRecording(questionId, answers)
        }
      }, 1000)

      // Not all browsers support Web Speech API
      if (browserSupportsSpeechRecognition) {
        resetTranscript()
        await SpeechRecognition.startListening({
          language: SPEECH_LANGUAGES[language] ?? 'en-US',
          continuous: true
        })
      }

      mediaRecorder = new MediaRecorder(mainStream)
      mediaRecorder.start()
    }
  })
}
