export interface IErrorResponse {
  message: string | string[]
  statusCode: number
  path: string
  timestamp: Date
}

export enum VrMode {
  interactive = 'interactive',
  demo = 'demo',
  preview = 'preview',
  questionPreivew = 'questionPreivew'
}

export interface TokenResponse {
  accessToken: string
  refreshToken: string
}
