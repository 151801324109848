import 'aframe'
import React, { FC } from 'react'

import { Theme } from '../../constants'
import { useCustomFont } from '../../hooks'

const ProcessingNotification: FC<{
  questionId: string
  iconPosition: string
  textPosition: string
  visible?: boolean
}> = (props) => {
  const fontProps = useCustomFont()

  return (
    <>
      <a-entity
        visible={!!props.visible}
        id={`processingIcon_${props.questionId}`}
        geometry="primitive: plane;width: 0.1; height: 0.1;"
        material="src: url(/assets/retry-info.svg); transparent: true;"
        position={props.iconPosition}
      />
      <a-entity
        id={`processingText_${props.questionId}`}
        position={props.textPosition}
        visible={!!props.visible}
      >
        <a-troika-text
          anchor="left"
          align="left"
          value="Speech processing. Please wait..."
          position="0 -0.01 0"
          color={Theme.colors.black}
          max-width="1.14"
          font-size="0.05"
          {...fontProps}
        />
      </a-entity>
    </>
  )
}

export default ProcessingNotification
