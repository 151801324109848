import 'aframe'
import React, { FC, Fragment } from 'react'

import AnswerTitle from '../answer-title'
import CheckAnswerIcon from '../check-answer-icon'

const AnswerItem: FC<{
  answerId: string
  titlePosition: string
  value: string
  checkIconPosition: string
  isCorrect: boolean
  show?: boolean
}> = (props) => {
  return (
    <a-entity>
      <AnswerTitle
        id={`questionDescription_${props.answerId}`}
        position={props.titlePosition}
        value={props.value}
        negate={false}
      />
      <CheckAnswerIcon
        id={`correct_${props.answerId}`}
        iconPath={'/assets/check-mark.svg'}
        position={props.checkIconPosition}
        show={props.show && props.isCorrect}
      />
      <CheckAnswerIcon
        id={`incorrect_${props.answerId}`}
        iconPath={'/assets/check-error-mark.svg'}
        position={props.checkIconPosition}
        show={props.show && !props.isCorrect}
      />
    </a-entity>
  )
}

export default AnswerItem
