export const Theme = {
  colors: {
    black: '#000000',
    white: '#ffffff',
    primary: '#7b27f2',
    line: '#118A7E',
    blue: '#2626EA',
    sky: '#12A5FF'
  }
}
