import 'aframe'
import 'aframe-troika-text'

import { useCustomFont } from '../../hooks'
import HelpPointButton from '../help-point-button'

interface Props {
  id: string
  text: string
  height: number
}

const HelpWrapper = ({ id, height, text }: Props) => {
  const fontProps = useCustomFont()
  const containerWidth = 1.5
  const textContainerWidth = containerWidth - 0.06

  return (
    <a-entity id={`help_${id}`} position="0 -1.25 0.6" visible="false">
      <HelpPointButton
        id={`helpCloseButton_${id}`}
        position={`${containerWidth / 2 - 0.15} ${height / 2 - 0.25} 0.021`}
      />
      <a-entity
        position="0 -0.25 0"
        geometry={`primitive: plane; width: ${containerWidth}; height: ${
          height + 0.3
        };`}
        material={`src: url(/assets/modal-wrapper.svg); transparent: true;`}
      />
      <a-entity
        id={`help_${id}`}
        position="0 -0.35 0.01"
        geometry={`primitive: plane; width: ${textContainerWidth}; height: ${
          height - 0.05
        };`}
        material="color: white;"
      >
        <a-troika-text
          align="center"
          anchor="center"
          baseline="center"
          value={text}
          position="0 0 0.01"
          color="black"
          max-width={textContainerWidth}
          overflow-wrap="break-word"
          font-size="0.05"
          line-height="1.4"
          {...fontProps}
        />
      </a-entity>
    </a-entity>
  )
}

export default HelpWrapper
