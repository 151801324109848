import { FC, memo, useEffect } from 'react'

import {
  IPreviewQuestion,
  Theme,
  getCheckIconPosition,
  getHelpPointButtonPosition,
  getHelpWrapperHeight,
  getModalTitlePosition,
  getPrimaryButtonPosition,
  getQuestionModalHeight,
  getRepeatButtonPosition,
  getRetryNotificationIconPosition,
  getRetryNotificationTextPosition,
  getTitlePosition
} from '../../shared'
import AnswerItem from '../../shared/ui/answer-item'
import HandControls from '../../shared/ui/hand-controls'
import HelpPointButton from '../../shared/ui/help-point-button'
import HelpWrapper from '../../shared/ui/help-wrapper'
import PrimaryButtonWrapper from '../../shared/ui/primary-button-wrapper'
import ProcessingNotification from '../../shared/ui/processing-notification'
import QuestionTitle from '../../shared/ui/question-title'
import QuestionWrapper from '../../shared/ui/question-wrapper'
import RecordButton from '../../shared/ui/record-button'
import RepeatButton from '../../shared/ui/repeat-button'
import { registerPanel } from './panel'

interface Props {
  question: IPreviewQuestion
}

registerPanel({ name: 'preview-panel' })

const VrQuestionPreviewWidget: FC<Props> = ({ question }) => {
  const modalHeight = getQuestionModalHeight(
    question.answers.length,
    question.text.length
  )

  return (
    <a-scene
      cursor="rayOrigin: mouse; fuse: false"
      raycaster="objects: .raycastable"
      xr-mode-ui="XRMode: vr;"
      background={`color: ${Theme.colors.sky}`}
    >
      <HandControls />

      <a-camera user-height="0" wasd-controls-enabled="false">
        <a-entity id="ui" position="0 1.6 -2.5">
          <QuestionWrapper visible id="preview-modal" height={modalHeight}>
            <QuestionTitle
              id="preview-title"
              value={question.text}
              position={getModalTitlePosition(
                modalHeight,
                question.text.length
              )}
            />
            {question.answers.map((answer, key) => {
              return (
                <AnswerItem
                  key={`key_${answer.id}`}
                  answerId={answer.id}
                  isCorrect={answer.isCorrect}
                  show
                  titlePosition={getTitlePosition(
                    key,
                    modalHeight,
                    question.text.length
                  )}
                  value={answer.text}
                  checkIconPosition={getCheckIconPosition(
                    key,
                    modalHeight,
                    question.text.length
                  )}
                />
              )
            })}
            <ProcessingNotification
              visible
              questionId="preview-processing-notification"
              iconPosition={getRetryNotificationIconPosition(modalHeight)}
              textPosition={getRetryNotificationTextPosition(modalHeight)}
            />
            <RepeatButton
              id="preview"
              position={getRepeatButtonPosition(modalHeight)}
            />
            <PrimaryButtonWrapper
              id="preview"
              position={getPrimaryButtonPosition(modalHeight)}
            >
              <RecordButton id="preview-record-btn" />
            </PrimaryButtonWrapper>
            {question.hintText && (
              <HelpPointButton
                id="preview-help-open-btn"
                position={getHelpPointButtonPosition(modalHeight)}
              />
            )}
          </QuestionWrapper>
          {question.hintText && (
            <HelpWrapper
              id="preview-modal"
              height={getHelpWrapperHeight(
                question.hintText,
                modalHeight,
                question.language
              )}
              text={question.hintText}
            />
          )}
        </a-entity>
      </a-camera>

      <a-entity preview-panel />
    </a-scene>
  )
}

export default memo(VrQuestionPreviewWidget)
