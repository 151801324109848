import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'

import Pages from '../pages'
import { Theme } from '../shared'
import { store } from './store'
import { GlobalStyles } from './styles'

const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={Theme}>
        <GlobalStyles />
        <Pages />
      </ThemeProvider>
    </Provider>
  )
}

export default App
