import 'aframe'
import 'aframe-troika-text'
import React, { FC } from 'react'

import { Theme } from '../../constants'
import { useCustomFont } from '../../hooks'

const AnswerTitle: FC<{
  id: string
  position: string
  value: string
  negate: boolean
}> = (props) => {
  const fontProps = useCustomFont()

  return (
    <a-entity id={props.id} position={props.position}>
      <a-circle
        color={Theme.colors.black}
        radius="0.01"
        position="-0.04 0 0.01"
      />
      <a-troika-text
        anchor="left"
        align="left"
        value={props.value}
        position="0 0 0.01"
        color={Theme.colors.black}
        max-width="1.14"
        overflow-wrap="break-word"
        font-size="0.05"
        line-height="1.4"
        {...fontProps}
      />
    </a-entity>
  )
}

export default AnswerTitle
