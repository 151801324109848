import 'aframe'
import React, { FC } from 'react'

const RecordButton: FC<{
  id: string
}> = (props) => {
  return (
    <a-entity
      id={`recordButton_${props.id}`}
      geometry="primitive: plane;width: 0.2; height: 0.2;"
      material="src: url(/assets/record-icon.svg); transparent: true;"
      animation__scale="property: scale; to: 1.1 1.1 1.1; dur: 200; startEvents: mouseenter"
      animation__scale_reverse="property: scale; to: 1 1 1; dur: 200; startEvents: mouseleave"
      position="0 0 0"
    />
  )
}

export default RecordButton
