import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom'

import { ROUTES } from '../shared'
import MainLayout from '../widgets/main-layout'
import DemoPage from './demo-page'
import EditQuestionPreviewPage from './edit-question-preview-page'
import InteractivePage from './interactive-page'
import PreviewPage from './preview-page'

const router = createBrowserRouter([
  {
    path: ROUTES.HOME
  },
  {
    element: <MainLayout />,
    children: [
      {
        path: ROUTES.VR_EDIT_QUESTION_PREVIEW,
        element: <EditQuestionPreviewPage />
      },
      {
        path: ROUTES.VR_DEMO.path,
        element: <DemoPage />
      },
      {
        path: ROUTES.VR_PREVIEW.path,
        element: <PreviewPage />
      },
      {
        path: ROUTES.VR_INTERACTIVE.path,
        element: <InteractivePage />
      }
    ]
  },
  {
    path: '*',
    element: <Navigate to={ROUTES.HOME} />
  }
])

const Pages = () => {
  return <RouterProvider router={router} />
}

export default Pages
