import 'aframe'

import { VrComponentOptions } from '../../shared'

export function registerPanel({ name }) {
  if (AFRAME.components[name]) {
    delete AFRAME.components[name]
  }

  AFRAME.registerComponent(name, {
    ...VrComponentOptions,
    init: function () {
      const openHelpBtn = this.getElementById('preview-help-open-btn')
      if (openHelpBtn) {
        this.helpOpenBtnStateToggle(true)

        openHelpBtn.addEventListener('click', () => {
          this.toggleElementVisibility('preview-modal', false)

          this.helpCloseBtnStateToggle(true)
          this.helpOpenBtnStateToggle(false)

          this.toggleElementVisibility('help_preview-modal', true)
        })

        this.getElementById('helpCloseButton_preview-modal').addEventListener(
          'click',
          () => {
            this.toggleElementVisibility('preview-modal', true)

            this.helpCloseBtnStateToggle(false)
            this.helpOpenBtnStateToggle(true)

            this.toggleElementVisibility('help_preview-modal', false)
          }
        )
      }

      this.ui = this.getElementById('ui')
      this.ui.object3D.position.x = 0
      this.ui.object3D.position.y = 1.6
      this.ui.object3D.position.z = -2.5
      if (AFRAME.utils.device.isMobileVR()) {
        this.ui.object3D.position.z = -3.5
        this.ui.object3D.position.y = 1.25
      }
    },

    helpOpenBtnStateToggle: function (visible) {
      this.toggleElementVisibility('preview-help-open-btn', visible)
      this.toggleElementClickEvents('preview-help-open-btn', visible)
    },

    helpCloseBtnStateToggle: function (visible) {
      this.toggleElementVisibility('helpCloseButton_preview-modal', visible)
      this.toggleElementClickEvents('helpCloseButton_preview-modal', visible)
    }
  })
}
