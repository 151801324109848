import { Entity } from 'aframe'
import axios from 'axios'

import {
  ACCESS_DEMO_TOKEN_NAME,
  ACCESS_TOKEN_NAME,
  API_ROUTES,
  REFRESH_TOKEN_NAME,
  SPEECH_LANGUAGES,
  hintWrapCount,
  oneRowHeight,
  questionTitleLineHeight,
  questionTitleMarginTop,
  questionTitleWrapCount,
  recordActionsHeight
} from '../constants'
import { IAnswer, TokenResponse } from '../types'

export const getAverageScore = (answersResults: IAnswer[]) => {
  let rightAnswersCount = 0
  answersResults.forEach((ans: any) => {
    if (ans.isCorrect) rightAnswersCount += 1
  })
  return Math.round((rightAnswersCount / answersResults.length) * 100)
}

export const calculateQuestionTitleMarginTop = (numberOfLines: number) => {
  return questionTitleMarginTop + numberOfLines * questionTitleLineHeight
}

export const getFirstAnswerPaddingTop = (questionLength: number) => {
  const linesCount = Math.floor(questionLength / questionTitleWrapCount) || 1
  const questionTitleTopPosition = calculateQuestionTitleMarginTop(linesCount)

  return questionTitleTopPosition + questionTitleLineHeight * linesCount + 0.1
}
export const getTitlePosition = (
  rowNumber: number,
  modalHeight: number,
  questionLength: number
) => {
  const paddingTop = getFirstAnswerPaddingTop(questionLength)
  const positionX = -0.6
  let positionY = modalHeight / 2 - paddingTop
  const positionZ = 0

  if (rowNumber > 0) {
    positionY = positionY - 0.2 * rowNumber
  }

  return `${positionX} ${positionY} ${positionZ}`
}

export const getCheckIconPosition = (
  rowNumber: number,
  modalHeight: number,
  questionLength: number
) => {
  const paddingTop = getFirstAnswerPaddingTop(questionLength)
  const positionX = 0.62
  let positionY = modalHeight / 2 - paddingTop
  const positionZ = 0.01

  if (rowNumber > 0) {
    positionY = positionY - 0.2 * rowNumber
  }

  return `${positionX} ${positionY} ${positionZ}`
}

export const getTextWrapCount = (text: string) => {
  return text.split('\n').length - 1
}

export const getModalTitlePosition = (
  modalHeight: number,
  questionLength: number
) => {
  const linesCount = Math.floor(questionLength / 30) || 1
  return `-0.68 ${
    modalHeight / 2 - calculateQuestionTitleMarginTop(linesCount)
  } 0`
}

export const getQuestionTitleHeight = (linesCount: number) => {
  return linesCount * questionTitleLineHeight
}

export const getQuestionModalHeight = (
  answersCount: number,
  questionTitleLength: number
) => {
  const linesCount =
    Math.floor(questionTitleLength / questionTitleWrapCount) || 1
  const titleHeight = getQuestionTitleHeight(linesCount)
  const titleMarginTop = calculateQuestionTitleMarginTop(linesCount)

  const answersHeight = answersCount * oneRowHeight

  return titleHeight + titleMarginTop + answersHeight + recordActionsHeight
}

export const getRepeatButtonPosition = (modalHeight: number) => {
  const bottomPadding = 0.25
  return `-0.15 -${modalHeight / 2 - bottomPadding} 0.1`
}

export const getPrimaryButtonPosition = (modalHeight: number) => {
  const bottomPadding = 0.25
  return `0.15 -${modalHeight / 2 - bottomPadding} 0.1`
}

export const getHelpPointButtonPosition = (modalHeight: number) => {
  return `0.6 ${modalHeight / 2 - calculateQuestionTitleMarginTop(1)} 0.01`
}

export const getHelpWrapperHeight = (
  hint: string,
  modalHeight: number,
  language: string
) => {
  const wrapCount =
    hintWrapCount[SPEECH_LANGUAGES[language]] || hintWrapCount.default
  const hintLinesCount = Math.floor(hint.length / wrapCount) || 1
  const hintHeight = getQuestionTitleHeight(
    hintLinesCount + getTextWrapCount(hint)
  )

  const topPadding = getFirstAnswerPaddingTop(1)
  const minHeight = modalHeight - topPadding

  return hintHeight < minHeight ? minHeight : hintHeight
}

export const getRetryNotificationIconPosition = (modalHeight: number) => {
  const bottomPadding = 0.45
  return `-0.55 -${modalHeight / 2 - bottomPadding} 0.1`
}

export const getRetryNotificationTextPosition = (modalHeight: number) => {
  const bottomPadding = 0.46
  return `-0.45 -${modalHeight / 2 - bottomPadding} 0.1`
}

export const VrComponentOptions = {
  removeClickEventsClasses: function (element: Entity<HTMLElement>) {
    element.classList.remove('raycastable', 'menu-button')
  },
  addClickEventsClasses: function (element: Entity<HTMLElement>) {
    element.classList.add('raycastable', 'menu-button')
  },
  getElementById: function (
    id: string
  ): Entity<HTMLElement> | null | undefined {
    return document.getElementById(id) as Entity<HTMLElement> | null | undefined
  },
  toggleElementClickEvents: function (id: string, isEnabled: boolean) {
    const element = this.getElementById(id)
    if (!element) return
    if (isEnabled) {
      this.addClickEventsClasses(element)
    } else {
      this.removeClickEventsClasses(element)
    }
  },
  toggleElementVisibility: function (id: string, isVisible: boolean) {
    const element = this.getElementById(id)
    if (!element) return
    element.object3D.visible = isVisible
  }
}

export const logVrInfo = (
  mode: string,
  browserSupportsSpeechRecognition: boolean,
  recognitionIsEnabled: boolean,
  useAiModel: boolean
) => {
  // eslint-disable-next-line
  console.group('VrInfo')
  // eslint-disable-next-line
  console.log('mode:', mode)
  // eslint-disable-next-line
  console.log(
    'browser support speech recognition:',
    browserSupportsSpeechRecognition
  )
  // eslint-disable-next-line
  console.log('recognition is enabled:', recognitionIsEnabled)
  // eslint-disable-next-line
  console.log('use ai model:', !useAiModel)
  // eslint-disable-next-line
  console.groupEnd()
}

export const removeTokens = () => {
  sessionStorage.removeItem(ACCESS_TOKEN_NAME)
  sessionStorage.removeItem(REFRESH_TOKEN_NAME)
}

export const setTokens = (accessToken: string, refreshToken: string) => {
  sessionStorage.setItem(ACCESS_TOKEN_NAME, accessToken)
  sessionStorage.setItem(REFRESH_TOKEN_NAME, refreshToken)
}

export const refreshDemoToken = async () => {
  const { data } = await axios.post<TokenResponse>(
    `${process.env.REACT_APP_PUBLIC_API_BASE_URL}/${API_ROUTES.LOGIN_DEMO_USER}`
  )
  sessionStorage.setItem(ACCESS_DEMO_TOKEN_NAME, data.accessToken)
  return data.accessToken
}

export const refreshAuthToken = async (refreshToken: string) => {
  try {
    const { data } = await axios.post<TokenResponse>(
      `${process.env.REACT_APP_PUBLIC_API_BASE_URL}/${API_ROUTES.REFRESH}`,
      {
        refreshToken
      }
    )
    setTokens(data.accessToken, data.refreshToken)
    return data.accessToken
  } catch (error) {
    removeTokens()
  }
}

export const getCurrentToken = async () => {
  const accessToken = sessionStorage.getItem(ACCESS_TOKEN_NAME)
  if (accessToken) return accessToken

  const refreshToken = sessionStorage.getItem(REFRESH_TOKEN_NAME)
  if (refreshToken) {
    const result = await refreshAuthToken(refreshToken)
    if (result) {
      return result
    }
  }

  const accessDemoToken = sessionStorage.getItem(ACCESS_DEMO_TOKEN_NAME)
  if (accessDemoToken) return accessDemoToken

  return await refreshDemoToken()
}

export const refreshCurrentToken = async () => {
  const refreshToken = sessionStorage.getItem(REFRESH_TOKEN_NAME)
  if (refreshToken) {
    const result = await refreshAuthToken(refreshToken)
    if (result) {
      return result
    }
  }
  return await refreshDemoToken()
}
