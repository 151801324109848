export const CUSTOM_FONT_PATH = '/fonts/custom-regular.ttf'

export const SPEECH_LANGUAGES: Record<string, string> = {
  en: 'en-US',
  fr: 'fr-FR',
  it: 'it-IT',
  ko: 'ko',
  sp: 'es-ES',
  ge: 'de-DE',
  ch: 'zh-CN'
}

export const questionLineHeight = 30

export const oneRowHeight = 0.23

export const necessaryElementsHeight = 0.9
export const questionTitleMarginTop = 0.05
export const questionTitleLineHeight = questionLineHeight / 1000 + 0.05

export const questionTitleWrapCount = 25
export const hintWrapCount: Record<string, number> = {
  [SPEECH_LANGUAGES.ko]: 28,
  [SPEECH_LANGUAGES.ch]: 28,
  default: 50
}

export const resultTitleMarginTop = 0.185
export const recordActionsHeight = 0.45
