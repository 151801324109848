import 'aframe'
import 'aframe-troika-text'
import React, { FC } from 'react'

import { Theme } from '../../constants'
import { useCustomFont } from '../../hooks'

const QuestionTitle: FC<{
  value: string
  id: string
  position: string
}> = (props) => {
  const fontProps = useCustomFont()

  return (
    <a-entity id={props.id} position={props.position}>
      <a-troika-text
        anchor="left"
        align="left"
        position="0 0 0.01"
        value={props.value}
        color={Theme.colors.black}
        max-width="1.15"
        overflow-wrap="break-word"
        font-size="0.06"
        line-height="1.4"
        {...fontProps}
      />
    </a-entity>
  )
}

export default QuestionTitle
