import { FC, useCallback, useState } from 'react'

import { IPreviewQuestion } from '../../shared'
import Loader from '../../shared/ui/loader'
import VrQuestionPreviewWidget from '../../widgets/vr-question-preview-widget'
import { Root } from './styles'

const EditQuestionPreviewPage: FC = () => {
  const [question, setQuestion] = useState<IPreviewQuestion | null>(null)

  const handleMessage = useCallback(
    (event: MessageEvent) => {
      if (event.data.key == 'question') {
        const newQuestion = event.data.data
        setQuestion(newQuestion)
        window.removeEventListener('message', handleMessage)
      }
    },
    [setQuestion]
  )

  window.addEventListener('message', handleMessage)

  if (!question) return <Loader />

  return (
    <Root>
      <VrQuestionPreviewWidget question={question} />
    </Root>
  )
}

export default EditQuestionPreviewPage
